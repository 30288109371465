export default [
  {
    label: "Item 1",
    value: 10
  },
  {
    label: "Item 2",
    value: 20
  },
  {
    label: "Item 3",
    value: 20
  }
];
